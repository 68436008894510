import { Http } from "@/http"
import { Id, res_list, req_list } from '@/types/global'

export interface add_banner{
    id:Id
    url:string
    type:number

}

export interface skill_data{
    begin:string
    end:string
    price:number
}

export interface share_data{
    start_amount:number
    amount:number
    end:string
    begin:string
}

export interface scheme{
    name:string
    person_limit:number
    make_amount:number
    content:string
}
export interface banner extends add_banner{
   
}
export interface setBox{
    cabinetId:Id,
    number:number
}

class HttpStore extends Http{
	get_list = (data:req_list) => {
		return this.get<res_list<banner>>({...data})
	}

    get_info = (id:number) => {
        return this.get<banner>({},`/admin/banner/${id}/`)
    }

    create = (data:banner) => {
        return this.post(data)
    }

    edit = ({id,...data}: banner) => {
        return this.patch(data,`/admin/banner/${id}/`)
    }

    del_one = (id:number) => {
        return this.delete(id)
    }

}

export const api_banner =  new HttpStore('/admin/banner/')
