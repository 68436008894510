







































































import { Mixin_list } from "@/mixin";
import { api_city, city } from "@/http/city";
import { api_banner, banner } from "@/http/banner";
import { Component } from "vue-property-decorator";
import Page from "@/components/page/index.vue";
import Table from "@/components/table/index.vue";
import DatePicker from "@/components/datePicker/index.vue";

@Component({
  components: {
    Page,
    Table,
    DatePicker,
  },
})
export default class extends Mixin_list<banner>(api_banner.get_list) {
  filter = {
    update_time_after: "",
    update_time_before: "",
    name: "",
    id: "",
  };
  resetFilter = {
    ...this.filter,
  };
  columns = [
    {
      label: "ID",
      prop: "id",
      width: 200
    },
    {
      label: "图片",
      slot: "url",
    },
    {
      label: "操作",
      prop: "",
      slot: "operation",
    },
  ];
  getImageUrl(cover: string) {
    if (cover && typeof cover === "string") {
      // 将字符串按逗号分割成数组
      const coverIds = cover.split(",");
      // 取数组的第一个元素作为图片ID
      const coverId = coverIds[0].trim();
      // 拼接图片URL
      return `https://images.xiyuebiaoju.com/${coverId}`;
    }
  }

  created() {
    // this.get_city_list();
  }

  click_cell(row: banner) {
    return this.$router.push(`/banner/edit/?id=${row.id}`);
  }

  async del(id: number) {
    await api_banner.del_one(id);
    this.get_list();
  }
}
